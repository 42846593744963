<template>
  <nuxt-link :to="link" class="components-essentials-defaultbutton-container">
    <slot>Button</slot>
  </nuxt-link>
</template>

<script>
export default {
  props: {
    link: {
      type: Object,
      required: false,
      default: () => ({ name: 'me-overview' }),
    },
  },
};
</script>

<style lang="scss" scoped>
.components-essentials-defaultbutton-container {
  @apply border-theme-primary text-theme-primary font-theme-title text-lg bg-transparent border-2 border-solid h-12 flex items-center justify-center tracking-title uppercase px-6;
}
</style>
